import { useEffect, useState } from "react";
import NextLink from "next/link";
import {
  Text,
  Heading,
  Button,
  Link as A,
  Box,
  crukTheme,
  IconFa,
} from "@cruk/cruk-react-components";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";

import { HomePageSearch } from "@fwa/src/components/HomePageSearch";
import { CircleWrapper } from "@fwa/src/components/CircleWrapper";
import { BubbleCard } from "@fwa/src/components/BubbleCard";
import { HomePageImpactStatement } from "@fwa/src/components/HomePageImpactStatement";
import { HomePageImpactMoney } from "@fwa/src/components/HomePageImpactMoney";

import {
  ColumnCenter,
  ContentMaxWidth,
  ContentWrapperResponsive,
  Row,
  RowCenter,
} from "@fwa/src/components/styles";
import {
  HeroWrapper,
  HeroBtnWrapper,
  HeroContentWrapper,
  HeroImage,
  BubbleText,
  CircleWrapperFixedWidth,
  JumpToButton,
  GivingPageDemoImageWrapper,
  SectionWrapper,
  FacebookLogoWrapper,
  StravaLogoWrapper,
  FitBitLogoWrapper,
  ConnectAppImagesGrid,
  HeroBubble,
  // ImpactStatementWrapper,
  ImpactStatementCopyWrapper,
  ImpactJumpToButtonCopyWrapper,
  ImpactJumpToTitle,
  TopBubbleMobileSpacer,
} from "@fwa/src/components/HomePage/styles";
import { useBrandContext } from "@fwa/src/contexts/BrandContext";

// const heroImage = "/assets/images/img/homepage_hero_512_512.jpg"; To be deleted
// after one month kept here for backward compatibility if any

const heroImage = "/assets/images/img/homepage_hero_hands-1024.jpeg";
const featureImageGivingPage =
  "/assets/images/img/homepage_features_giving_page_example.png";
const featureImageMsgBoard =
  "/assets/images/img/homepage_features_msg_board.jpg";
const featureImageRun = "/assets/images/img/homepage_features_fun_run.jpg";
const featureImageCarWash = "/assets/images/img/homepage_features_car_wash.jpg";
const faceBookLogo = "/assets/images/logos/fb-logo.png";
const stravaLogo = "/assets/images/logos/strava-logo-circle.png";
const fitBitLogo = "/assets/images/logos/fitbit-logo.png";
const impactStatement100 = "/assets/images/img/impact_statement_100.jpg";
const impactStatement250 = "/assets/images/img/impact_statement_250.jpg";
const impactStatement500 = "/assets/images/img/impact_statement_500.jpg";
const impactStatement1000 = "/assets/images/img/impact_statement_1000.jpg";
const impactStatement2500 = "/assets/images/img/impact_statement_2500.jpg";
const impactStatement5000 = "/assets/images/img/impact_statement_5000.jpg";

const scrollToTarget = (targetId: string) => {
  document
    .getElementById(`${targetId}`)
    ?.scrollIntoView({ behavior: "smooth" });
};

export const HomePage = () => {
  const { sendTrackingEvent } = useTrackingContext();
  const [visibleImpactStatements, setVisibleImpactStatements] = useState<
    number[]
  >([]);

  // useEffect to force brandContext on browser navigation
  const [brandName, setBrandName] = useBrandContext();

  useEffect(() => {
    if (brandName === "cruk") return;
    setBrandName("cruk");
  }, [brandName, setBrandName]);

  return (
    <ContentMaxWidth data-component="homepage">
      <HeroWrapper>
        <HeroImage src={heroImage} alt="" />
        <HeroContentWrapper>
          <CircleWrapper backgroundColor={crukTheme.tokenColors.navy_600}>
            <HeroBubble
              $variation={0}
              $inView={false}
              $backgroundColor="secondary"
            />
            <HeroBubble
              $variation={1}
              $inView={false}
              $backgroundColor="tertiary"
            />
            <ColumnCenter>
              <RowCenter>
                <Heading
                  h2
                  as="h1"
                  textColor={crukTheme.tokenColors.white}
                  textAlign="center"
                  marginTop="s"
                >
                  Fundraise online for Cancer Research UK
                </Heading>
              </RowCenter>
              <HeroBtnWrapper data-component="hero">
                <NextLink
                  href="/signup?create=true"
                  onClick={() => {
                    sendTrackingEvent({
                      event: "create_fundraising_page_cta",
                      cta: "hero",
                    });
                  }}
                  data-cta-type="create-page"
                >
                  <Button appearance="primary" as="span">
                    Start fundraising
                  </Button>
                </NextLink>
              </HeroBtnWrapper>
            </ColumnCenter>
          </CircleWrapper>
        </HeroContentWrapper>
      </HeroWrapper>
      <ContentWrapperResponsive marginBottom="xxl">
        <Heading
          h4
          as="h2"
          textAlign="center"
          textColor={crukTheme.tokenColors.navy_600}
          paddingBottom="l"
        >
          Join thousands of supporters raising money for life-saving work now.
        </Heading>
      </ContentWrapperResponsive>
      <HomePageSearch />
      {/* ---------------------- Features section -------------------------------- */}
      <ContentWrapperResponsive
        paddingBottom="m"
        backgroundColor="backgroundLight"
        marginTop="s"
      >
        <TopBubbleMobileSpacer>
          <CircleWrapperFixedWidth>
            <ColumnCenter>
              <RowCenter>
                <Heading
                  h2
                  textAlign="center"
                  textColor={crukTheme.tokenColors.navy_600}
                  paddingHorizontal="s"
                >
                  {`We're here to help you fundraise`}
                </Heading>
              </RowCenter>
              <JumpToButton
                aria-label="scroll to giving page information section"
                onClick={() => scrollToTarget("givingPageSection")}
              >
                <div>
                  <IconFa
                    faIcon={faArrowDown}
                    size="3rem"
                    color={crukTheme.tokenColors.white}
                  />
                </div>
              </JumpToButton>
            </ColumnCenter>
          </CircleWrapperFixedWidth>
        </TopBubbleMobileSpacer>
        <SectionWrapper>
          <Box id="givingPageSection" marginTop="m">
            <BubbleCard
              imageRight={false}
              variation={4}
              thirdBubbleColor={crukTheme.tokenColors.grey_300}
              imageChild={
                <GivingPageDemoImageWrapper>
                  <img src={featureImageGivingPage} alt="Giving page example" />
                </GivingPageDemoImageWrapper>
              }
            >
              <BubbleText>
                <Heading h2 as="h3">
                  Get a head start with a Giving Page
                </Heading>
                <Text>
                  {`When you sign up to fundraise with Cancer Research UK, we
                  automatically create a Giving Page. So you can start
                  fundraising as soon as you're ready. On your Giving Page, you
                  can share your story, fundraising target, progress and much
                  more.`}
                </Text>
              </BubbleText>
            </BubbleCard>
          </Box>

          <BubbleCard
            imageUrl={featureImageMsgBoard}
            alt=""
            imageRight
            variation={1}
          >
            <Box>
              <BubbleText>
                <Heading h2 as="h3">
                  Make every pound count
                </Heading>
                <Text>
                  {`Fundraising directly through Cancer Research UK means we won't
                  have to pay other charitable platform fees so a larger chunk
                  of the money you raise will go directly to our amazing
                  research.`}
                </Text>
              </BubbleText>
            </Box>
          </BubbleCard>

          <BubbleCard
            imageRight={false}
            variation={5}
            thirdBubbleColor={crukTheme.tokenColors.grey_300}
            imageChild={
              <ConnectAppImagesGrid>
                <FacebookLogoWrapper>
                  <img src={faceBookLogo} alt="Facebook logo" />
                </FacebookLogoWrapper>
                <StravaLogoWrapper>
                  <img src={stravaLogo} alt="Strava logo" />
                </StravaLogoWrapper>
                <FitBitLogoWrapper>
                  <img src={fitBitLogo} alt="Fit bit Logo" />
                </FitBitLogoWrapper>
              </ConnectAppImagesGrid>
            }
          >
            <Box>
              <BubbleText>
                <Heading h2 as="h3">
                  Connect to your favourite apps
                </Heading>
                <Text>
                  {`You can connect your Giving Page with social and fitness
                  accounts to share your progress with friends and family.
                  People are more likely to donate when they can see every mile,
                  step or cycle.`}
                </Text>
              </BubbleText>
            </Box>
          </BubbleCard>

          <BubbleCard
            imageUrl={featureImageRun}
            alt=""
            imageRight
            variation={2}
          >
            <Box>
              <BubbleText>
                <Heading h2 as="h3">
                  Fundraise together and beat cancer together
                </Heading>
                <Text>
                  {`You can create a team for your fundraising challenge and smash
                  those targets as a squad. No team is too big or too small to
                  show cancer who's boss. You can do this easily from your
                  Giving Page.`}
                </Text>
              </BubbleText>
            </Box>
          </BubbleCard>

          <BubbleCard
            imageUrl={featureImageCarWash}
            alt=""
            imageRight={false}
            variation={3}
          >
            <Box>
              <BubbleText>
                <Heading h2 as="h3">
                  Share your fundraising journey
                </Heading>
                <Text>
                  {`Personalise your Giving Page with your story and photos to
                  tell everyone why you're fundraising for Cancer Research UK.
                  People who share their story raise more money towards beating
                  their fundraising target.`}
                </Text>
              </BubbleText>
            </Box>
          </BubbleCard>
        </SectionWrapper>
      </ContentWrapperResponsive>
      <Box backgroundColor="backgroundLight" paddingHorizontal="none">
        <CircleWrapper
          backgroundColor={crukTheme.tokenColors.darkBlue_100}
          data-component="middle-ctas"
        >
          <Text textAlign="center" textSize="l">
            {`Cancer won't wait.`}
          </Text>
          <Box marginBottom="xs">
            <RowCenter>
              <NextLink
                href="/signup?create=true"
                onClick={() => {
                  sendTrackingEvent({
                    event: "create_fundraising_page_cta",
                    cta: "fundraise now - middle",
                  });
                }}
                data-cta-type="create-page"
              >
                <Button appearance="primary" as="span">
                  Start fundraising
                </Button>
              </NextLink>
            </RowCenter>
          </Box>
          <Text textAlign="center" textSize="l">
            Need a little more support?
          </Text>
          <RowCenter>
            <A
              href="https://activities.cancerresearchuk.org/fundraising-reg-pack-info"
              textAlign="center"
              onClick={() => {
                sendTrackingEvent({
                  event: "create_fundraising_page_cta",
                  cta: "request pack - middle",
                });
              }}
              data-cta-type="link-request-pack"
            >
              <Button as="span" appearance="secondary">
                Get a fundraising pack
              </Button>
            </A>
          </RowCenter>
        </CircleWrapper>
      </Box>
      <Box>
        <CircleWrapper
          backgroundColor={crukTheme.colors.primary}
          hasOverflow
          maxWidth="27em"
        >
          <ContentWrapperResponsive>
            <ImpactJumpToButtonCopyWrapper>
              <RowCenter>
                <ImpactJumpToTitle
                  textSize="xl" // the design is larger (40px) than this component allows.
                  textAlign="left"
                  textColor={crukTheme.colors.textOnPrimary}
                >
                  Every year, two million amazing fundraisers raise over £40m
                </ImpactJumpToTitle>
              </RowCenter>
              <RowCenter>
                <Text
                  textAlign="left"
                  textColor={crukTheme.colors.textOnPrimary}
                >
                  See how your fundraising could make a difference to our
                  life-saving work
                </Text>
              </RowCenter>
              <Box paddingTop="s">
                <JumpToButton
                  aria-label="scroll to giving page information section"
                  onClick={() => scrollToTarget("impact-statements")}
                >
                  <div>
                    <IconFa
                      faIcon={faArrowDown}
                      size="3rem"
                      color={crukTheme.tokenColors.white}
                    />
                  </div>
                </JumpToButton>
              </Box>
            </ImpactJumpToButtonCopyWrapper>
          </ContentWrapperResponsive>
        </CircleWrapper>
      </Box>

      {/* ---------------------- Impact statements section -------------------------------- */}
      <SectionWrapper id="impact-statements">
        <HomePageImpactStatement
          number={1}
          visibleImpactStatements={visibleImpactStatements}
          setVisibleImpactStatements={setVisibleImpactStatements}
        >
          <BubbleCard
            imageUrl={impactStatement100}
            alt=""
            imageRight
            variation={6}
            isAnimated={false}
          >
            <Row>
              <ImpactStatementCopyWrapper>
                <BubbleText>
                  <Heading h2 as="h3">
                    Spotting cancer earlier
                  </Heading>
                  <Text>
                    {`£100 could pay for a patient's cancer biopsy where a tiny
                    amount of a lump or tumour is removed and sent to be studied
                    in a lab. Early diagnosis is essential to getting the right
                    treatment to patients.`}
                  </Text>
                </BubbleText>
              </ImpactStatementCopyWrapper>
            </Row>
          </BubbleCard>
        </HomePageImpactStatement>

        <HomePageImpactStatement
          number={2}
          visibleImpactStatements={visibleImpactStatements}
          setVisibleImpactStatements={setVisibleImpactStatements}
        >
          <BubbleCard
            imageUrl={impactStatement250}
            thirdBubbleColor={crukTheme.tokenColors.grey_300}
            alt=""
            imageRight
            variation={7}
            isAnimated={false}
          >
            <Row>
              <ImpactStatementCopyWrapper>
                <BubbleText>
                  <Heading h2 as="h3">
                    {`Finding cancer's weakness`}
                  </Heading>
                  <Text>
                    {`£250 could buy a special device used to spin blood samples
                    until different types of blood cells separate. Our
                    scientists can then study the different types of cancerous
                    cells to find out more about them. So we can stop cancer in
                    its tracks.`}
                  </Text>
                </BubbleText>
              </ImpactStatementCopyWrapper>
            </Row>
          </BubbleCard>
        </HomePageImpactStatement>

        <HomePageImpactStatement
          number={3}
          visibleImpactStatements={visibleImpactStatements}
          setVisibleImpactStatements={setVisibleImpactStatements}
        >
          <BubbleCard
            imageUrl={impactStatement500}
            alt=""
            imageRight
            variation={8}
            isAnimated={false}
          >
            <Row>
              <ImpactStatementCopyWrapper>
                <BubbleText>
                  <Heading h2 as="h3">
                    Providing cancer support
                  </Heading>
                  <Text>
                    {`£500 could run our online forum, Cancer Chat for a day. It's
                    a safe space open to everyone to discuss any aspect of the
                    disease. Cancer is unpredictable, so let's give people
                    affected by cancer the support they need at every turn.`}
                  </Text>
                </BubbleText>
              </ImpactStatementCopyWrapper>
            </Row>
          </BubbleCard>
        </HomePageImpactStatement>

        <HomePageImpactStatement
          number={4}
          visibleImpactStatements={visibleImpactStatements}
          setVisibleImpactStatements={setVisibleImpactStatements}
        >
          <BubbleCard
            imageUrl={impactStatement1000}
            alt=""
            imageRight
            variation={9}
            isAnimated={false}
          >
            <Row>
              <ImpactStatementCopyWrapper>
                <BubbleText>
                  <Heading h2 as="h3">
                    Hunting innovative discoveries
                  </Heading>
                  <Text>
                    {`£1,000 could buy imaging software that our scientists can use
                    to film cells in real time. This could help to test
                    potentially life-saving treatments and find the most
                    promising new cancer drugs.`}
                  </Text>
                </BubbleText>
              </ImpactStatementCopyWrapper>
            </Row>
          </BubbleCard>
        </HomePageImpactStatement>

        <HomePageImpactStatement
          number={5}
          visibleImpactStatements={visibleImpactStatements}
          setVisibleImpactStatements={setVisibleImpactStatements}
        >
          <BubbleCard
            imageUrl={impactStatement2500}
            alt=""
            imageRight
            variation={10}
            isAnimated={false}
          >
            <Row>
              <ImpactStatementCopyWrapper>
                <BubbleText>
                  <Heading h2 as="h3">
                    Delivering treatments
                  </Heading>
                  <Text>
                    {`£2,500 could fund the PETReA trial for five days. This trial
                    aims to help doctors decide how best to treat lymphoma as
                    not all patients can take the same drug. Everyone deserves
                    kind cancer treatments that won't impact their long-term
                    health.`}
                  </Text>
                </BubbleText>
              </ImpactStatementCopyWrapper>
            </Row>
          </BubbleCard>
        </HomePageImpactStatement>

        <HomePageImpactStatement
          number={6}
          visibleImpactStatements={visibleImpactStatements}
          setVisibleImpactStatements={setVisibleImpactStatements}
        >
          <BubbleCard
            imageUrl={impactStatement5000}
            alt=""
            imageRight
            variation={11}
            isAnimated={false}
          >
            <Row>
              <ImpactStatementCopyWrapper>
                <BubbleText>
                  <Heading h2 as="h3">
                    Keeping cancer in remission
                  </Heading>
                  <Text>
                    {`£5,000 could fund the Add-Aspirin trial for five days. This
                    clinical trial is investigating whether aspirin could stop
                    cancer coming back after treatment. If successful, aspirin
                    would be a cheap and readily available intervention that
                    could save lives.`}
                  </Text>
                </BubbleText>
              </ImpactStatementCopyWrapper>
            </Row>
          </BubbleCard>
        </HomePageImpactStatement>
      </SectionWrapper>
      {/* This exists just so the last elememt before is selected more easily */}
      <HomePageImpactStatement
        number={7}
        visibleImpactStatements={visibleImpactStatements}
        setVisibleImpactStatements={setVisibleImpactStatements}
      >
        <Box
          backgroundColor="backgroundLight"
          paddingHorizontal="none"
          paddingBottom="m"
        >
          <CircleWrapper
            backgroundColor={crukTheme.tokenColors.darkBlue_100}
            data-component="bottom-ctas"
          >
            <Text textAlign="center" textSize="l">
              {`Cancer won't wait.`}
            </Text>
            <Box marginBottom="xs">
              <RowCenter>
                <NextLink
                  href="/signup?create=true"
                  onClick={() => {
                    sendTrackingEvent({
                      event: "create_fundraising_page_cta",
                      cta: "fundraise now - bottom",
                    });
                  }}
                  data-cta-type="create-page"
                >
                  <Button appearance="primary" as="span">
                    Start fundraising
                  </Button>
                </NextLink>
              </RowCenter>
            </Box>
            <Text textAlign="center" textSize="l">
              Need a little more support?
            </Text>
            <RowCenter>
              <A
                href="https://activities.cancerresearchuk.org/fundraising-reg-pack-info"
                textAlign="center"
                data-cta-type="link-request-pack"
              >
                <Button
                  as="span"
                  appearance="secondary"
                  onClick={() => {
                    sendTrackingEvent({
                      event: "create_fundraising_page_cta",
                      cta: "request pack - bottom",
                    });
                  }}
                >
                  Get a fundraising pack
                </Button>
              </A>
            </RowCenter>
          </CircleWrapper>
        </Box>
      </HomePageImpactStatement>
      <HomePageImpactMoney visibleImpactStatements={visibleImpactStatements} />
    </ContentMaxWidth>
  );
};

export default HomePage;
